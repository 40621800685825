<!-- eslint-disable vue/no-v-html -->
<template>
  <Container class="flex flex-col items-center">
    <div class="w-full max-w-[650px]">
      <div
        v-if="data?.copy"
        class="custom-list prose lg:prose-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark font-sans text-base text-base leading-[21px] lg:text-lg lg:leading-[26px]"
        :class="['center', 'mx-auto', 'text-black', blockquoteStyles]"
        v-html="data?.copy"
      ></div>
      <Link
        v-if="data?.link?.url && data?.linkType === 'blue'"
        :title="data?.link?.title"
        class="mt-6 lg:mt-8"
        :link="data.link.url"
        :external="data?.link?.type === 'ext'"
      />
      <LinkSecondary
        v-else-if="data?.link?.url && data?.linkType === 'blue-arrow'"
        :title="data?.link?.title"
        class="mt-6 lg:mt-8"
        :link="data.link.url"
        :external="data?.link?.type === 'ext'"
        type="blue"
      />
    </div>
  </Container>
</template>

<script setup lang="ts">
import Link from '../atoms/Link.vue'
import LinkSecondary from '../atoms/LinkSecondary.vue'
import Container from '../atoms/Container.vue'
import type { ILinkData } from '../../types'

const blockquoteStyles = [
  'prose-blockquote:bg-blue-dark',
  'prose-blockquote:p-4',
  'prose-blockquote:border-blue-dark',
  'prose-blockquote:relative',
  'prose-blockquote:mx-auto',
  'prose-blockquote:w-fit',
  'prose-blockquote:rounded-3xl',
  'prose-blockquote:rounded-tl-none',
].join(' ')

export interface IContentTextBlock {
  id: string
  copy?: string
  link?: ILinkData
  linkType?: string
}

interface Props {
  data: IContentTextBlock
}

defineProps<Props>()
</script>

<style scoped>
:deep(.prose blockquote p) {
  color: white !important;
  margin-bottom: 0;
}
:deep(.prose blockquote p:first-of-type::before),
:deep(.prose blockquote p:last-of-type::after) {
  content: none !important;
}
</style>
