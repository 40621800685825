<!-- eslint-disable vue/no-v-html -->
<template>
  <Container class="flex flex-col items-center">
    <div :class="data?.position === 'center' && 'text-center'" class="w-full max-w-[650px]">
      <SubTitle v-if="data?.subtitle" class="text-blue">
        {{ data?.subtitle }}
      </SubTitle>
      <Title v-if="data?.title" custom-style="mb-5 leading-none lg:mb-6 lg:leading-none">
        {{ data?.title }}
      </Title>
      <div
        v-if="data?.copy"
        class="prose lg:prose-lg prose-p:text-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:prose-p:text-xl font-sans text-base leading-[21px] lg:leading-[26px]"
        :class="[
          data.position === 'center' && 'mx-auto',
          data.color === 'blue'
            ? 'font-bold !text-blue [&_p]:font-bold [&_p]:text-blue'
            : 'text-black',
        ]"
        v-html="data?.copy"
      />
      <div
        v-if="data?.body"
        class="prose lg:prose-lg prose-p:text-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:prose-p:text-xl custom-list pt-4 font-sans text-base leading-[21px] text-black lg:leading-[26px]"
        :class="blockquoteStyles"
        v-html="data?.body"
      />
      <Link
        v-if="data?.link"
        :title="data.link.title"
        class="mt-5 lg:mt-6"
        :link="data.link.url"
        :external="data.link?.type === 'ext'"
      />
      <slot name="actions" />
    </div>
  </Container>
</template>

<script setup lang="ts">
import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import Link from '../atoms/Link.vue'
import Container from '../atoms/Container.vue'
import type { ILinkData } from '../../types'

const blockquoteStyles = [
  'prose-blockquote:bg-blue-dark',
  'prose-blockquote:p-4',
  'prose-blockquote:border-blue-dark',
  'prose-blockquote:relative',
  'prose-blockquote:mx-auto',
  'prose-blockquote:w-fit',
  'prose-blockquote:rounded-3xl',
  'prose-blockquote:rounded-tl-none',
].join(' ')

export interface IIntroBlock {
  id: string
  position?: string
  subtitle?: string
  title?: string
  body?: string
  copy?: string
  link?: ILinkData
  color?: string
}

interface Props {
  data: IIntroBlock
}

defineProps<Props>()
</script>

<style scoped>
:deep(.prose blockquote p) {
  color: white !important;
  margin-bottom: 0;
}
:deep(.prose blockquote p:first-of-type::before),
:deep(.prose blockquote p:last-of-type::after) {
  content: none !important;
}
</style>
