<template>
  <FormPetition
    v-if="!document?.closed"
    :petition-id="props.petitionId as string"
    :db-override="document.dbOverride || ''"
    :is-protest="isProtest"
    :endpointId="document?.endpoint?.sys?.id"
    :campaignCode="document?.actionCode"
    class="mt-4"
  />
</template>

<script lang="ts" setup>
import type { ActionDocument } from '../../../types/contentfulCms'
import FormPetition from '@/components/molecules/FormPetition.vue'
import { useAsyncData, useNuxtApp } from '#app'

const { $client } = useNuxtApp()

const props = defineProps({
  petitionId: String,
})

const { data: dataContentful } = await useAsyncData(`petition--${props.petitionId}`, () =>
  $client.getEntries({
    content_type: 'action',
    'fields.slug': props.petitionId,
    limit: 1,
  }),
)

const document = (dataContentful?.value?.items[0]?.fields || {}) as unknown as ActionDocument
const isProtest = Boolean(document?.mailTemplate)
</script>
