<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="showConfirmation"
    ref="confirmation"
    class="space-y-4 rounded-xl bg-blue-lighter p-6 lg:rounded-2xl lg:p-9"
  >
    <h2
      v-if="data.confirmation.title"
      class="mb-6 font-sans text-3xl font-bold text-blue lg:text-4xl"
    >
      {{ data.confirmation.title }}
    </h2>
    <div
      class="custom-list prose font-sans text-base leading-[21px] lg:prose-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:text-lg lg:leading-[26px]"
      v-html="data.confirmation.message"
    ></div>
  </div>

  <form
    :name="data.id"
    v-else-if="data?.elements"
    ref="formRef"
    class="space-y-4 rounded-xl bg-blue-lighter px-6 py-4 lg:rounded-2xl lg:p-9"
    @submit.prevent="onSubmit"
  >
    <h4 class="mb-10 font-serif text-xl text-black lg:text-2xl">{{ data?.title }}</h4>
    <ClientOnly>
      <div v-if="data.status === FORM_STATUS.CLOSED">
        <p v-if="data.form_close_message" v-html="data.form_close_message" class="text-pink"></p>
        <p v-else class="text-pink">{{ $t('form-close-message') }}</p>
      </div>

      <div v-if="data.status === FORM_STATUS.SCHEDULED && disabledForm">
        <p v-if="data.form_open_message" v-html="data.form_open_message" class="text-pink"></p>
        <p v-else class="text-pink">
          {{
            $t('form-open-message', {
              start: dayjs(data.open).format('DD-MM-YYYY'),
              end: dayjs(data.close).format('DD-MM-YYYY'),
            })
          }}
        </p>
      </div>
    </ClientOnly>
    <WebformElement
      v-for="item in data.elements"
      :key="item[0]"
      v-model="formFields[item[0]]"
      :iid="item[0]"
      :element="item[1]"
      :is-loading="isLoading"
      :form-id="data.id"
      :v$="v$"
      :disabled="disabledForm"
      :field_button_variant="data?.field_button_variant"
    />
    <ClientOnly>
      <p class="font-bold text-red-600">{{ error }}</p>
    </ClientOnly>
  </form>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

import { useRuntimeConfig } from '#app'
import { useI18n } from '#imports'
import { ssnValidOrHasBirthDate } from 'vuelidate-validators'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { reactive, ref, watch, nextTick, computed } from 'vue'
import { useForm, useRecaptcha, flattenObj, FORM_STATUS } from 'refresh-common'
import type { FormType } from 'refresh-common'
import WebformElement from '@/components/molecules/WebformElement.vue'

const config = useRuntimeConfig()
const { t } = useI18n()

const props = defineProps<{ data: FormType }>()

const disabledForm = computed(() => {
  const { status, open, close } = props.data

  const startDate = open
  const endDate = close || dayjs().add(1, 'd')

  if (!status) {
    return false
  }

  if (status === FORM_STATUS.OPEN) {
    return false
  }

  if (status === FORM_STATUS.CLOSED) {
    return true
  }

  if (status === FORM_STATUS.SCHEDULED && dayjs().isBetween(startDate, endDate)) {
    return false
  }

  return true
})

const { isLoading, sendForm, updateDataForm, error } = useForm({
  cmsBasePath: config.public.cmsBasePath,
})
const { addRecatcha, recaptcha } = useRecaptcha({
  recaptchaSiteKey: config.public.recaptchaSiteKey,
})

const formFields = reactive({})

const formRef = ref()
const showConfirmation = ref(false)
const confirmation = ref(null)

const stateValidation = reactive({
  agent_ssn: '',
  receiver_ssn: '',
})

updateDataForm(props.data.id, {})

watch(formFields, () => {
  addRecatcha()
  const fields = flattenObj(JSON.parse(JSON.stringify(formFields)))

  if ('agent_ssn' in fields) {
    stateValidation.agent_ssn = fields.agent_ssn
  }

  if ('receiver_ssn' in fields) {
    stateValidation.receiver_ssn = fields.receiver_ssn
  }
})

const unwrapKeys = (inputArray: any[]) => {
  const resultsArray = inputArray.map((inputString: string) => {
    const startIndex = inputString.indexOf('[') + 1
    const endIndex = inputString.indexOf(']')

    if (startIndex !== -1 && endIndex !== -1) {
      return inputString.substring(startIndex, endIndex)
    } else {
      return ''
    }
  })
  return resultsArray
}

const hasBrackets = (arr: any[]) => {
  if (Array.isArray(arr) === false) {
    return false
  }
  const regex = /^[a-zA-Z0-9_]+\[[a-zA-Z0-9]+\]$/
  for (let str of arr) {
    if (regex.test(str)) {
      return true
    }
  }
  return false
}

const rules = computed(() => {
  let localRules = {}
  const fields = flattenObj(JSON.parse(JSON.stringify(formFields)))

  if ('agent_ssn' in fields) {
    localRules = {
      agent_ssn: {
        ssn: helpers.withMessage(t('ssn-description'), ssnValidOrHasBirthDate),
      },
    }
  }

  if ('receiver_ssn' in fields) {
    localRules = {
      receiver_ssn: {
        ssn: helpers.withMessage(t('ssn-description'), ssnValidOrHasBirthDate),
      },
    }
  }

  return localRules
})

const v$ = useVuelidate(rules, stateValidation)

const onSubmit = async () => {
  v$.value.$touch

  if (v$.value.$invalid) return false

  const success = await recaptcha()

  if (!success) {
    return
  }

  // CheckboxesMulti values are wrapped in a certain way to make conditional fields work, here we check for that and unwrap them
  const data = flattenObj(JSON.parse(JSON.stringify(formFields)))
  const result = {}
  const original = Object.entries(data)
  original.forEach(([key, value]) => {
    if (hasBrackets(value)) {
      result[key] = unwrapKeys(value)
    } else {
      result[key] = value
    }
  })
  const sended = await sendForm(props.data.id, result)
  if (sended) {
    showConfirmation.value = true
    await nextTick()
    confirmation.value && confirmation.value.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>
