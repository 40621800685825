import payload_plugin_RWJZBrqztrCKuXVwL_RHS5SVtN2nFo9xJzjOIfmO1PA from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.1.0_firebase-functions@6_1d23382541203429d4dd2ec0f52a50d6/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_dKAMu00h8y9z3UtKKtLPUAvy2A56akz1hLHellcYG6o from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.0_@netlify+blobs@8.1.1_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_9e3ac077c0940a4f6f11fb7498be6986/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uu6uwk_Yb33UBsFcwupc4ec_bogvUwyDEcmVmkGfOhU from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.0_@netlify+blobs@8.1.1_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_9e3ac077c0940a4f6f11fb7498be6986/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HKLQfZPJzSWmSlXwGhK6Iqh9f4XM9ICGvkYOt1jJ7cs from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.0_@netlify+blobs@8.1.1_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_9e3ac077c0940a4f6f11fb7498be6986/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ucUVfmuufGcgcVereSP73X8qKu2sMlqFu27hgRIU_t8 from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.1.4_magicast@0.3.5_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_iYgTZRWcqvf9dNikm8pqp2tLcQaBTvNdsWw5kyl660k from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.0_@netlify+blobs@8.1.1_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_9e3ac077c0940a4f6f11fb7498be6986/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HOMJv_zLN50JVuXcujb3fzJKcajDwS6pGlOH7WLR9T4 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.0_@netlify+blobs@8.1.1_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_9e3ac077c0940a4f6f11fb7498be6986/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Rca88FVZ3MskApvSgfMNATlb_lF4LtGMXPrGr_2Ekfg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.0_@netlify+blobs@8.1.1_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_9e3ac077c0940a4f6f11fb7498be6986/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_u1x3VEiCVYbY20eMVaT6u3EbFmVEj_O69zLzr2_nPP0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.0_@netlify+blobs@8.1.1_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_9e3ac077c0940a4f6f11fb7498be6986/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/render/project/src/apps/refresh/.nuxt/components.plugin.mjs";
import prefetch_client_nPaDg5c5DPcyL3UEvBzrabRfIASOlEY5CY4xVFS055o from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.0_@netlify+blobs@8.1.1_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_9e3ac077c0940a4f6f11fb7498be6986/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import i18n_BQXyURtRJKS9fnBfW9GS_AGqB_OpD3p_SxjggwYPUfA from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.1.4_magicast@0.3.5_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_XOY2inkCaSEh0LNpCwTNwMu5YLF0AsCCT2zKj1eEtgc from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.7.2___magica_b86108872172781fa8f95bff205a11c2/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_hwUFYWzm3Pb4VjMSO49rUNaji1QONwGlso4sLKZr28Q from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.1.0_magicast@0.3.5_rollup@4.35.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_xtc7CyUnnvKHMGmWXd53NhGC622ULHdHVAV6fVQzy6U from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.1.0_firebase-functions@6_1d23382541203429d4dd2ec0f52a50d6/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M from "/opt/render/project/src/apps/refresh/.nuxt/vuefire-plugin.mjs";
import contentful_Usfel6V0Vns9VmnNZHhV_8k_ptW8RSPbHv_mAeZ889s from "/opt/render/project/src/apps/refresh/plugins/contentful.js";
import rollbar_lyhHRDrx0vOfatlSi6JTVSwQEijf1TZUlCVZo2hT59U from "/opt/render/project/src/apps/refresh/plugins/rollbar.ts";
import socialShare_GiW9qfs4iQiRvAwa10mX0puoImpW7XXd5ZRVV23tdWs from "/opt/render/project/src/apps/refresh/plugins/socialShare.js";
export default [
  payload_plugin_RWJZBrqztrCKuXVwL_RHS5SVtN2nFo9xJzjOIfmO1PA,
  revive_payload_client_dKAMu00h8y9z3UtKKtLPUAvy2A56akz1hLHellcYG6o,
  unhead_uu6uwk_Yb33UBsFcwupc4ec_bogvUwyDEcmVmkGfOhU,
  router_HKLQfZPJzSWmSlXwGhK6Iqh9f4XM9ICGvkYOt1jJ7cs,
  _0_siteConfig_ucUVfmuufGcgcVereSP73X8qKu2sMlqFu27hgRIU_t8,
  payload_client_iYgTZRWcqvf9dNikm8pqp2tLcQaBTvNdsWw5kyl660k,
  navigation_repaint_client_HOMJv_zLN50JVuXcujb3fzJKcajDwS6pGlOH7WLR9T4,
  check_outdated_build_client_Rca88FVZ3MskApvSgfMNATlb_lF4LtGMXPrGr_2Ekfg,
  chunk_reload_client_u1x3VEiCVYbY20eMVaT6u3EbFmVEj_O69zLzr2_nPP0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_nPaDg5c5DPcyL3UEvBzrabRfIASOlEY5CY4xVFS055o,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  i18n_BQXyURtRJKS9fnBfW9GS_AGqB_OpD3p_SxjggwYPUfA,
  plugin_XOY2inkCaSEh0LNpCwTNwMu5YLF0AsCCT2zKj1eEtgc,
  i18n_hwUFYWzm3Pb4VjMSO49rUNaji1QONwGlso4sLKZr28Q,
  plugin_client_xtc7CyUnnvKHMGmWXd53NhGC622ULHdHVAV6fVQzy6U,
  vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M,
  contentful_Usfel6V0Vns9VmnNZHhV_8k_ptW8RSPbHv_mAeZ889s,
  rollbar_lyhHRDrx0vOfatlSi6JTVSwQEijf1TZUlCVZo2hT59U,
  socialShare_GiW9qfs4iQiRvAwa10mX0puoImpW7XXd5ZRVV23tdWs
]